import { PropsWithChildren } from "react";
import { Button, ExternalLink, Icon } from "@biblioteksentralen/js-utils";
import { LinkButton } from "@libry-content/types";
import { PortableTextTypeComponentProps } from "@portabletext/react";
import { Link } from "@biblioteksentralen/js-utils";
import { useTranslation } from "../../utils/hooks/useTranslation";
import { useCommonData } from "../layout/CommonDataProvider";
import { isUrlInternal } from "../../utils/isUrlInternal";

type Props = PropsWithChildren<PortableTextTypeComponentProps<LinkButton>>;

export function TextLinkButtonSerializer({ value }: Props) {
  const { ts } = useTranslation();
  const isInternal = isUrlInternal(useCommonData().site, value.url);
  if (!ts(value?.label) || !value?.url) return null;

  return (
    <Link href={value.url} display="block" textAlign="center" marginBottom="2rem">
      <Button variant="secondary">
        {ts(value.label)}
        {!isInternal && (
          <Icon as={ExternalLink} margin="0 0 1px 0.5rem" top="0.1rem" position="relative" width="0.75em" />
        )}
      </Button>
    </Link>
  );
}
